<template>
  <div class="showPdf" style="padding: 0">
    <iframe id="iframe" :src="'/static/pdfjs/web/viewer.html?file=' + baseUrl + $route.query.filePath"></iframe>
  </div>
</template>

<script>
export default {
  name: 'showPdf',
}
</script>

<style>
  @import "../../assets/css/showPdf.css";
</style>
